import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import { rem } from 'polished';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledThumbnailSlider = styled.div`
  width: 100%;
  .thumbnail-slider-wrap {
    &:after {
      content: '';
      height: ${rem(`60px`)};
      width: 100%;
      display: block;
      ${({ theme }) => theme.breakpoints.phone`
        height: ${rem(`96px`)};
      `}
    }
  }
  .thumbnail-slider-wrap .slick-track {
    display: flex;
    align-items: center;
    ${({ theme }) => theme.breakpoints.desktop`
      padding-top: ${rem(`24px`)};
    `}
  }
  .thumbnail-slider-wrap .slick-track .slick-slide {
    text-align: center;
  }
  .thumbnail-slider-wrap .slick-dots {
    bottom: initial;
    top: 100%;
  }
  .thumbnail-slider-wrap .slick-slider {
    ${({ theme }) => theme.breakpoints.desktop`
      padding-bottom: ${rem(`24px`)};
    `}
  }
  .thumbnail-slider-wrap .slick-slider .slick-list {
    margin: 0 -${rem(`6px`)};
    ${({ theme }) => theme.breakpoints.desktop`
      margin: 0 -${rem(`14px`)};
    `}
  }

  .thumbnail {
    position: relative;
    display: flex;
    padding: 0 ${rem(`6px`)};
    ${({ theme }) => theme.breakpoints.desktop`
      padding: 0 ${rem(`14px`)};
    `}
    a {
      display: block;
      width: 100%;
      height: 100%;
      ${({ theme }) => theme.breakpoints.desktop`
        &:hover {
          .thumbnail__text {
            opacity: 1;
            transition: opacity 500ms ease-in-out;
          }
          .thumbnail__image {
            img {
              animation: thumbnailZoomBlur 600ms forwards;
            }
          }
        }
      `}
    }
  }
  .thumbnail__icon {
    position: absolute;
    z-index: 1099;
    top: calc(100% + 12px);
    width: ${rem(`36px`)};
    height: ${rem(`36px`)};
    color: ${({ theme }) => theme.colors.yellow_2};
    font-size: ${rem(`36px`)};
    line-height: ${rem(`36px`)};
    ${({ theme }) => theme.breakpoints.phone`
      top: calc(100% + 12px);
      width: ${rem(`48px`)};
      height: ${rem(`48px`)};
      font-size: ${rem(`48px`)};
      line-height: ${rem(`48px`)};
    `}
    ${({ theme }) => theme.breakpoints.desktop`
      top: calc(100% + 12px);
      width: ${rem(`72px`)};
      height: ${rem(`72px`)};
      font-size: ${rem(`72px`)};
      line-height: ${rem(`72px`)};
    `}
    &--next {
      right: ${rem(`12px`)};
      ${({ theme }) => theme.breakpoints.desktop`
        right: ${rem(`37px`)};
      `}
    }
    &--prev {
      left: ${rem(`12px`)};
      ${({ theme }) => theme.breakpoints.desktop`
        left: ${rem(`37px`)};
      `}
    }
    ${({ theme }) => theme.breakpoints.desktop`
      transition: color 300ms ease-in-out;
      &:hover {
        color: ${theme.colors.pink_2};
        transition: color 300ms ease-in-out;
      }
    `}
  }
  .thumbnail__dots-container {
    padding: ${rem(`12px`)};
    background-color: ${({ theme }) => theme.colors.blue_1_65};
    border-radius: ${rem(`12px`)};
  }
  .thumbnail__dots {
    padding: 0 ${rem(`42px`)};
    ${({ theme }) => theme.breakpoints.phone`
      padding: 0 ${rem(`60px`)};
    `}
    ${({ theme }) => theme.breakpoints.desktop`
      padding: 0 ${rem(`72px`)};
    `}
    li {
      width: ${rem(`12px`)};
      height: ${rem(`36px`)};
      margin: 0 ${rem(`4px`)};
      ${({ theme }) => theme.breakpoints.phone`
        width: ${rem(`24px`)};
        height: ${rem(`48px`)};
        margin: 0 ${rem(`5px`)};
      `}
      ${({ theme }) => theme.breakpoints.desktop`
        width: ${rem(`36px`)};
        height: ${rem(`72px`)};
      `}
    }
    li button {
      position: relative;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      ${({ theme }) => theme.breakpoints.desktop`
        &:hover:before {
          opacity: 1;
          color: ${theme.colors.purple_2};
          transition: opacity 300ms ease-in-out;
        }
      `}
    }
    li button:before {
      right: 0;
      left: ${rem(`-4px`)};
      top: ${rem(`2px`)};
      bottom: 0;
      margin: auto;
      font-size: ${rem(`18px`)};
      opacity: 0.25;
      color: ${({ theme }) => theme.colors.purple_2};
      transition: opacity 300ms ease-in-out;
      ${({ theme }) => theme.breakpoints.phone`
        left: 0;
        top: 0;
      `}
    }
    li.slick-active button:before {
      opacity: 1;
      color: ${({ theme }) => theme.colors.yellow_2};
    }
  }
`;

interface IThumbnailSlider {
  slides: any;
}

interface IArrowButton {
  onClick?: any;
}

function SampleNextArrow({ onClick }: IArrowButton) {
  return (
    <button
      aria-label="Next"
      type="button"
      className="thumbnail__icon thumbnail__icon--next"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronCircleRight} />
    </button>
  );
}

function SamplePrevArrow({ onClick }: IArrowButton) {
  return (
    <button
      aria-label="Prev"
      type="button"
      className="thumbnail__icon thumbnail__icon--prev"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronCircleLeft} />
    </button>
  );
}

export const ThumbnailSlider = ({ slides }: IThumbnailSlider) => {
  const [nav, setNav] = useState(undefined);
  const [sliderRef, setSliderRef] = useState(undefined);

  const settingsThumbs = {
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    asNavFor: `.slider-for`,
    dots: true,
    fade: false,
    focusOnSelect: false,
    arrows: true,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 2500,
    cssEase: `ease-in-out`,
    pauseOnHover: true,
    swipeToSlide: true,
    centerPadding: `0px`,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: (dots) => (
      <div>
        <div className="thumbnail__dots-container">
          <ul className="thumbnail__dots">{dots}</ul>
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setNav(sliderRef);
    return () => {
      setNav(undefined);
    };
  });

  return (
    <StyledThumbnailSlider>
      <div className="thumbnail-slider-wrap">
        <Slider
          {...settingsThumbs}
          asNavFor={nav}
          ref={(sliderInstance) => setSliderRef(sliderInstance)}
        >
          {slides.map((slide) => {
            return (
              <div className="thumbnail slick-slide" key={uuidv4()}>
                {slide}
              </div>
            );
          })}
        </Slider>
      </div>
    </StyledThumbnailSlider>
  );
};
