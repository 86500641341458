import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledHome {
  theme: any;
}

export const StyledHome = styled.div<IStyledHome>`
  height: auto;
  .first-letter {
    background: ${({ theme }) => theme.gradiants.rainbow_1};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  /* Section */
  .section {
    max-width: ${rem(`1200px`)};
    width: 100%;
    padding-top: ${rem(`60px`)};
    margin-bottom: ${rem(`196px`)};
    &:last-child {
      margin-bottom: 0;
    }
  }
  .main-title {
    font-size: 3em;
    ${(props) => props.theme.breakpoints.phone`
      font-size: 4em;
    `}
  }

  /* Services */
  .services {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: ${rem(`48px`)};
  }
  .service {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
  .service__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: ${rem(`60px`)};
    p {
      text-align: center;
    }
  }
  .service-icon {
    width: ${rem(`180px`)};
    height: ${rem(`183px`)};
    margin-bottom: ${rem(`24px`)};
  }
  .service-title {
    margin-bottom: ${rem(`24px`)};
  }

  .banner {
    margin-bottom: ${rem(`196px`)};
  }

  .banner__text {
    text-align: center;
    margin-bottom: ${rem(`13px`)};
  }

  /* Rectangle */
  .rectangle {
    max-width: ${rem(`1200px`)};
    display: flex;
    padding: ${rem(`72px`)} ${rem(`85px`)};
    border-radius: ${rem(`16px`)};
    position: relative;
    box-sizing: border-box;
    background-clip: padding-box;
    border: solid 1px transparent;
    background: ${({ theme }) => `linear-gradient(
      135deg,
      ${theme.colors.blue_1},
      ${theme.colors.blue_1_90}
    )`};
    ::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: inherit;
      margin: -2px;
      z-index: -1;
      background: ${({ theme }) => `linear-gradient(
          -170deg,
          ${theme.colors.blue_1_30},
          ${theme.colors.blue_1_65},
          ${theme.colors.pink_2}
        )`};
    }
    &--pad-36 {
      padding: ${rem(`36px`)};
    }
    &--no-bg {
      background: ${({ theme }) => theme.colors.blue_1};
    }
  }

  /* side-by-side */
  .side-by-side {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${rem(`48px`)};
  }

  .three-by-two-fifth {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: ${rem(`48px`)};
  }

  /* process-slider */
  .process-slider {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${rem(`48px`)};
  }
  .process-slider__image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* projects-slider */
  .project-slider-header {
    margin-bottom: ${rem(`36px`)};
  }

  /* About */
  .about {
    &__members {
      grid-column: 1/4;
      grid-row: 1;
    }
    &__content {
      grid-column: 4/6;
      grid-row: 1;
      padding-top: ${rem(`36px`)};
      p {
        margin-bottom: ${rem(`12px`)};
      }
    }
  }
  .team-members {
    margin-top: ${rem(`24px`)};
    display: grid;
    align-items: center;
    justify-content: flex-start;
    grid-gap: ${rem(`12px`)};
    grid-template-columns: repeat(2, 1fr);
    ${({ theme }) => theme.breakpoints.watch`
      grid-template-columns: repeat(1, 1fr);
    `}
    ${({ theme }) => theme.breakpoints.phone`
      grid-template-columns: repeat(2, 1fr);
    `}
    ${({ theme }) => theme.breakpoints.phablet`
      grid-template-columns: repeat(2, 1fr);
    `}
    ${({ theme }) => theme.breakpoints.tablet`
      grid-gap: ${rem(`24px`)};
      grid-template-columns: repeat(2, 1fr);
    `}
  }
  .section-header {
    margin-bottom: ${rem(`24px`)};
    &--extra {
      margin-bottom: ${rem(`36px`)};
    }
  }
  .section-subtitle {
    margin-bottom: ${rem(`6px`)};
    ${({ theme }) => theme.breakpoints.desktop`
      margin-bottom: ${rem(`24px`)};
    `}
    &--spacer {
      margin-bottom: ${rem(`12px`)};
      ${({ theme }) => theme.breakpoints.tablet`
        margin-bottom: ${rem(`18px`)};
      `}
      ${({ theme }) => theme.breakpoints.desktop`
        margin-bottom: ${rem(`24px`)};
      `}
    }
  }
  .services-title {
    margin-bottom: ${rem(`24px`)};
  }
  .cta {
    margin-bottom: ${rem(`24px`)};
  }
`;
